import { render, staticRenderFns } from "./CustomerAccountDetail.vue?vue&type=template&id=0e962903&scoped=true"
import script from "./CustomerAccountDetail.vue?vue&type=script&lang=js"
export * from "./CustomerAccountDetail.vue?vue&type=script&lang=js"
import style0 from "./CustomerAccountDetail.vue?vue&type=style&index=0&id=0e962903&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e962903",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VFlex,VLayout,VTab,VTabs})
