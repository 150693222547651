import { defineStore } from 'pinia'
import { Type } from '@/models/dto'
import {
  AccountNotificationSetting,
  accountNotificationsService, AccountNotificationWithParentData,
  SettingStatusWithParentStatus,
} from '@/services/accountNotifications'
import { getNotificationSettingsKey } from '@/utils/accountNotification'
import { computed, ref } from 'vue'

export const useAccountNotificationsStore = defineStore(
  'accountNotifications',
  () => {
    const recipientTypes = ref<Type[]>([])
    const settingTypes = ref<Type[]>([])
    const statusTypes = ref<Type[]>([])
    const notificationSettings = ref<AccountNotificationSetting[]>([])
    const accountNotificationSettingsWithParentData = ref<AccountNotificationWithParentData[]>([])

    const accountNotificationRecipientTypes = computed<Record<string, string>>(() => {
      return Object.fromEntries(recipientTypes.value.map(type => [type.key, type.label]))
    })
    const accountNotificationSettingTypes = computed<Record<string, Type>>(() => {
      return Object.fromEntries(settingTypes.value.map(type => [type.key, type]))
    })
    const accountNotificationStatusTypes = computed<Record<string, string>>(() => {
      return Object.fromEntries(statusTypes.value.map(type => [type.key, type.label]))
    })
    const accountNotificationSettings = computed<Record<string, SettingStatusWithParentStatus>>(() => {
      return Object.fromEntries(notificationSettings.value.map(setting => {
        const key = getNotificationSettingsKey(setting.notificationType, setting.recipientType)
        const parentDataStatus = accountNotificationSettingsWithParentData.value.find(parent => parent.notificationType === setting.notificationType && parent.recipientType === setting.recipientType)
        return [key, { status: setting.status, parentStatus: parentDataStatus?.isEnabled }]
      }))
    })

    const getAccountNotificationSettings = async (accountId: number): Promise<void> => {
      const resp = await accountNotificationsService.getAccountNotificationSettings(accountId)
      notificationSettings.value = resp.data.notificationSettings

      const respWithParentData = (await accountNotificationsService.getAccountNotificationSettingsWithParentData(accountId, resp.data.notificationSettings))
      accountNotificationSettingsWithParentData.value = respWithParentData.data.statusResponses
    }
    const getAccountNotificationTypes = async (): Promise<void> => {
      const [_recipientTypes, _settingTypes, _statusTypes] = await Promise.all([
        accountNotificationsService.getAccountNotificationRecipientTypes(),
        accountNotificationsService.getAccountNotificationSettingTypes(),
        accountNotificationsService.getAccountNotificationStatusTypes(),
      ])

      recipientTypes.value = _recipientTypes.data
      settingTypes.value = _settingTypes.data
      statusTypes.value = _statusTypes.data
    }
    const updateAccountNotificationSettings = async (accountId: number, payload: AccountNotificationSetting[]): Promise<void> => {
      await accountNotificationsService.updateAccountNotificationSettings(payload)
      await getAccountNotificationSettings(accountId)
    }

    return {
      recipientTypes,
      settingTypes,
      statusTypes,
      notificationSettings,
      accountNotificationRecipientTypes,
      accountNotificationSettingTypes,
      accountNotificationStatusTypes,
      accountNotificationSettings,
      getAccountNotificationSettings,
      getAccountNotificationTypes,
      updateAccountNotificationSettings
    }
  }
)
